
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductCatalogue from '../../../models/ProductCatalogue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { PopupEventData } from '@/interfaces/components/PopupEventData';
import { PopupEvents } from '@/enums/global/PopupEvents';
import { debounce } from 'vue-debounce';
import MaterialImageCarousel from '@/components/views/project/MaterialImageCarousel.vue';
import { KeyboardCodes } from '@/enums/global/KeyboardCodes';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import * as KeyboardHelper from '@/helpers/SearchMaterialsKeyboardHelper';
import OfferItem from '@/models/OfferItem';
import OfferTitle from '@/models/OfferTitle';
import { filterProductsFromOfferItems } from '@/helpers/OfferItemTableHelper';
import UserGroupRepository from '@/repositories/UserGroupRepository';

@Component({
  name: 'SearchMaterials',
  components: { MaterialImageCarousel },
})
export default class SearchMaterials extends Vue {
  @Prop({ required: true }) public projectId!: string;
  @Prop({ default: false }) public isNewDesign!: boolean;
  @Prop({ required: true }) public offerId!: string | null;
  @Prop({ default: null }) public clientsPaymentTypeId!: string | null;
  @Prop({ required: true }) private offerItems!: Array<OfferItem | OfferTitle>;
  private materialName = '';
  private isDropdownVisible = false;
  private selectedMaterialId = '';
  private searchedMaterials = [];

  private debouncedKeyPress = debounce((e: KeyboardEvent) => {
    this.onKeyPress(e);
  }, 100);
  private debouncedMaterialSearch = debounce(() => {
    this.onMaterialsSearch();
  }, 500);

  private get currentUserGroup() {
    return UserGroupRepository.getById(
      String(this.$store.getters['jwtData/currentUserGroup']),
    );
  }

  private get searchableMaterials() {
    if (this.materialName.length < 3) {
      this.isDropdownVisible = false;
      this.selectedMaterialId = '';
      return [];
    }

    const groupedMaterialsLists = this.searchedMaterials.reduce(
      (accumulator: any, element: any) => {
        // @ts-ignore
        const group = element.commodityGroup;
        // @ts-ignore
        if (!accumulator[group]) {
          // @ts-ignore
          accumulator[group] = { group, children: [element] };
        } else {
          // @ts-ignore
          accumulator[group].children.push(element);
        }
        return accumulator;
      },
      {},
    );
    return groupedMaterialsLists;
  }

  private get isSubmitButtonDisabled() {
    return this.selectedMaterialId === '';
  }

  private get selectedMaterial() {
    if (!this.selectedMaterialId) {
      return null;
    }
    return this.availableMaterials.find(
      (material: ProductCatalogue) => material.id === this.selectedMaterialId,
    );
  }

  private get availableMaterials() {
    return Object.keys(this.searchableMaterials)
      .map((key: string) => this.searchableMaterials[key].children)
      .flat();
  }

  private get selectedMaterialIndex() {
    return this.availableMaterials.findIndex(
      (material: ProductCatalogue) => material.id === this.selectedMaterialId,
    );
  }

  private async onMaterialsSearch() {
    if (this.materialName.trim().length < 3) {
      this.selectedMaterialId = '';
      return;
    }
    this.searchedMaterials = [];
    this.searchedMaterials = await ProductCatalogue.search(this.materialName);
    this.isDropdownVisible = true;
  }

  private onSelect(id: string) {
    const input = this.$refs.input as HTMLInputElement;
    input.focus();
    this.selectedMaterialId = id;
    return;
  }

  private onSubmit() {
    this.isDropdownVisible = false;
    const popupData: PopupEventData = {
      popupEvent: PopupEvents.openAddMaterials,
      data: {
        materialId: this.selectedMaterialId,
        projectId: this.projectId,
        offerId: this.offerId,
        clientsPaymentTypeId: this.clientsPaymentTypeId,
        offerItems: filterProductsFromOfferItems(this.offerItems, false),
      },
    };
    EventBus.$emit(EventBusEvents.openMaterialsPopup, popupData);
    this.selectedMaterialId = '';
    this.materialName = '';
  }

  private onKeyPress(e: KeyboardEvent) {
    if (!this.isDropdownVisible) {
      return;
    }

    switch (e.key) {
      case KeyboardCodes.ArrowUp:
        this.selectedMaterialId = KeyboardHelper.handleArrowUp(
          this.selectedMaterialId,
          this.availableMaterials,
          this.selectedMaterialIndex,
        );
        break;
      case KeyboardCodes.ArrowDown:
        this.selectedMaterialId = KeyboardHelper.handleArrowDown(
          this.selectedMaterialId,
          this.availableMaterials,
          this.selectedMaterialIndex,
        );
        break;
      case KeyboardCodes.Escape:
        this.isDropdownVisible = false;
        this.selectedMaterialId = '';
        return;
    }

    this.handleDropdownScroll();
  }

  private handleDropdownScroll() {
    const overlay = this.$refs.overlay as HTMLDivElement;
    if (
      this.selectedMaterialIndex <=
      GlobalOptions.MAX_VISIBLE_MATERIAL_DROPDOWN_ITEMS
    ) {
      overlay.scrollTo(0, 0);
    } else if (
      this.selectedMaterialIndex ===
      this.availableMaterials.length - 1
    ) {
      overlay.scrollTo(0, overlay.offsetHeight);
    } else {
      const activeElement = overlay.querySelector(
        '.selected',
      ) as HTMLDivElement;
      if (activeElement) {
        overlay.scrollTo(
          0,
          activeElement.offsetTop - activeElement.offsetHeight,
        );
      }
    }
  }

  private onPressEnter() {
    if (this.selectedMaterial) {
      this.onSubmit();
    }
  }
}
