import ProductCatalogue from '@/models/ProductCatalogue';

export function handleArrowUp(selectedMaterialId: string,
                              availableMaterials: ProductCatalogue[],
                              selectedMaterialIndex: number) {
    if (selectedMaterialId) {
        return selectPreviousMaterial(selectedMaterialId, availableMaterials, selectedMaterialIndex);
    } else {
        return selectLastMaterial(availableMaterials);
    }
}

export function handleArrowDown(selectedMaterialId: string,
                                availableMaterials: ProductCatalogue[],
                                selectedMaterialIndex: number) {
    if (selectedMaterialId) {
        return selectNextMaterial(selectedMaterialId, availableMaterials, selectedMaterialIndex);
    } else {
        return selectFirstMaterial(availableMaterials);
    }
}

function selectFirstMaterial(availableMaterials: any) {
    return availableMaterials[0].id;
}

function selectLastMaterial(availableMaterials: any) {
    return availableMaterials.slice(-1)[0].id;
}

function selectNextMaterial(selectedMaterialId: string, availableMaterials: any[], selectedMaterialIndex: number) {
    const targetIndex = selectedMaterialIndex === availableMaterials.length - 1 ? 0 : selectedMaterialIndex + 1;
    return availableMaterials[targetIndex].id;
}

function selectPreviousMaterial(selectedMaterialId: string, availableMaterials: any[], selectedMaterialIndex: number) {
    const targetIndex = selectedMaterialIndex === 0 ? availableMaterials.length - 1 : selectedMaterialIndex - 1;
    return availableMaterials[targetIndex].id;
}
