
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator';
import Image from '@/models/Image';

@Component({
    name: 'MaterialImageCarousel',
})
export default class MaterialImageCarousel extends Vue {
    @Prop({default: null}) public materialId!: string | null;
    @Prop({default: []}) public images!: Image[];
    @Ref('carousel') public carousel!: any;
    private activeSlide = 1;

    private onPageChange(page: number) {
        this.carousel.goTo(page - 1, false);
    }

    @Watch('materialId')
    private onMaterialChange() {
        this.activeSlide = 1;
        if (this.images.length && this.carousel) {
            this.carousel.goTo(1, false);
        }
    }

}
